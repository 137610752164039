




import Vue from 'vue';
import ProfileCard from '@/components/Settings/Card.vue';

export default Vue.extend({
  name: 'ContactCard',
  components: {
    ProfileCard,
  },
  data: () => ({
    content: {
      title: 'profile.cards.contact.title',
      items: [{
        icon: 'mdi-bell-outline',
        title: 'profile.cards.contact.notifications',
        path: '',
        comingSoon: true,
      }],
    },
  }),
});
